.containerAbout{
    display: flex;
    flex-direction:column;
}

.socialMedia{
    padding-top: 20px;
    display:flex;
    flex-direction:row;
    font-size:30px;
}
