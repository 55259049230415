#root {
  height: 100vh;
}

a {
    color: inherit !important;
}
amp-instagram{
    width: 100%;
    height: inherit;
    padding-top: 10px !important;
}

.fill-height{
    min-height: 100%;
    height: inherit;
}

.panel{
    color: white;
    padding-top:90px;
    height: 100%;
}

.customNavBar{
    width: 95%;
    margin: auto;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
}

.left-panel{
    background-color: #656262;
    font-weight: bold;
    font-size: 20px;
    padding-left: 30px !important;
}

.social-media{
    font-size: 40px;
    padding-bottom: 20px;
}

.social-media-icon{
    padding-right: 25px;
}

.right-panel{
    background-color: #D9D9D9;
    padding-left: 2% !important;
}

.ig-col{
    padding-left: 50px;
}


.terms-and-conditions-card{
    color: #000000;
}