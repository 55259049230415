.containerPixelArt{
    display: flex;
    flex-direction:column;
}

.carouselContainer{
    margin: 20px;
    border: 2px solid !important;
    border-color: black !important;
    border-radius:5px !important;
}
